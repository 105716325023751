import NolimitDefaultValues from './NolimitDefaultValues';

export default class NoLimit {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.clientId = data ? data.clientId : '';
    this.configurationName = data ? data.configurationName : '';
    this.password = data ? data.password : '';
    this.metadata = data ? data.metadata : [];
    this.courier = data ? data.courier : '';
    this.courierCode = data ? data.courierCode : 'noLimit';
    this.integration = data ? data.integration : 'NOLIMIT';
    this.login = data ? data.login : '';
    this.clientNoLimitId = data ? data.clientNoLimitId : '';
    this.urlAddress = data ? data.urlAddress : '';
    this.nolimitDefaultValues = new NolimitDefaultValues();
  }
}
